import { render } from "./ReceiveProgress.vue?vue&type=template&id=65a7a1f8&scoped=true"
import script from "./ReceiveProgress.vue?vue&type=script&lang=ts"
export * from "./ReceiveProgress.vue?vue&type=script&lang=ts"
script.render = render
script.__scopeId = "data-v-65a7a1f8"
/* hot reload */
if (module.hot) {
  script.__hmrId = "65a7a1f8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('65a7a1f8', script)) {
    api.reload('65a7a1f8', script)
  }
  
  module.hot.accept("./ReceiveProgress.vue?vue&type=template&id=65a7a1f8&scoped=true", () => {
    api.rerender('65a7a1f8', render)
  })

}

script.__file = "src/components/receive/ReceiveProgress.vue"

export default script