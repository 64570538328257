
import {defineComponent} from "vue";
import {IonText} from "@ionic/vue";
import {mapMutations, mapState} from "vuex";
import {HIDE_DRAG_ELEMENTS, SHOW_DRAG_ELEMENTS} from "@/store/actions";

export default defineComponent({
    name: "DropZone",
    props: ['select'],
    computed: {
        ...mapState(['showDragElements']),
    },
    methods: {
        ...mapMutations([
            HIDE_DRAG_ELEMENTS,
            SHOW_DRAG_ELEMENTS,
        ]),
        drop(event: DragEvent) {
            event.preventDefault();
            this[HIDE_DRAG_ELEMENTS]();

            const files = event.dataTransfer!.files;
            if (files.length > 0) {
                // NB: only dropping first file in list.
                this.select(files[0]);
            } else {
                console.error("no files listed in drop event")
            }
        },
        dragEnter(event: DragEvent): void {
            event.preventDefault();
            this[SHOW_DRAG_ELEMENTS]();
        },
        dragOver(event: DragEvent) {
            event.preventDefault();
        },
        dragLeave(event: DragEvent): void {
            event.preventDefault();
            this[HIDE_DRAG_ELEMENTS]();
        },
    },
    components: {
        IonText,
    },
})
