import { render } from "./Card.vue?vue&type=template&id=54cdc180&scoped=true"
import script from "./Card.vue?vue&type=script&lang=js"
export * from "./Card.vue?vue&type=script&lang=js"

import "./Card.vue?vue&type=style&index=0&id=54cdc180&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-54cdc180"
/* hot reload */
if (module.hot) {
  script.__hmrId = "54cdc180"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('54cdc180', script)) {
    api.reload('54cdc180', script)
  }
  
  module.hot.accept("./Card.vue?vue&type=template&id=54cdc180&scoped=true", () => {
    api.rerender('54cdc180', render)
  })

}

script.__file = "src/components/Card.vue"

export default script