
import {defineComponent} from "vue";
import {
    IonButton,
    IonCol,
    IonGrid,
    IonIcon,
    IonRow,
    IonText
} from "@ionic/vue";
import {mapState} from "vuex";
import {sendSharp} from "ionicons/icons";

import Card from "@/components/Card.vue";
import FileCard from "@/components/FileCard.vue";

export default defineComponent({
    name: "SendComplete",
    props: ['active', 'sendMore'],
    computed: {
        ...mapState(['fileMeta']),
    },
    components: {
        IonGrid,
        IonRow,
        IonCol,
        IonButton,
        IonIcon,
        IonText,
        Card,
        FileCard,
    },
    setup() {
        return {
            sendSharp,
        };
    },
});
