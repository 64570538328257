
import {IonApp, IonRouterOutlet, IonMenu} from '@ionic/vue';
import {defineComponent} from 'vue';
import Main from "@/views/Main.vue";

export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        Main,
    }
});
