import { render } from "./ReceiveConsent.vue?vue&type=template&id=1abea913&scoped=true"
import script from "./ReceiveConsent.vue?vue&type=script&lang=ts"
export * from "./ReceiveConsent.vue?vue&type=script&lang=ts"
script.render = render
script.__scopeId = "data-v-1abea913"
/* hot reload */
if (module.hot) {
  script.__hmrId = "1abea913"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1abea913', script)) {
    api.reload('1abea913', script)
  }
  
  module.hot.accept("./ReceiveConsent.vue?vue&type=template&id=1abea913&scoped=true", () => {
    api.rerender('1abea913', render)
  })

}

script.__file = "src/components/receive/ReceiveConsent.vue"

export default script