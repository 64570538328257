import {IonicVue} from '@ionic/vue';
import {createApp} from 'vue'

import App from '@/App.vue'
import router from '@/router';
import store from '@/store';

import {SET_CONFIG} from "../tests/e2e/support/const";


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import {RouteLocationNormalized} from "vue-router";

// TODO: move
if (process.env['NODE_ENV'] !== 'production') {
    window.addEventListener('message', ({data}: MessageEvent) => {
        if (typeof(data.action) !== 'undefined') {
            const {action, config} = data;
            switch (action) {
                case SET_CONFIG:
                    console.log(`setting config: ${JSON.stringify(config, null, '  ')}`)
                    store.dispatch('setConfig', config)
            }
        }
    })
}

const app = createApp(App)
    .use(IonicVue)
    .use(store)
    .use(router);

const routerReady = router.isReady();

Promise.all([routerReady]).then(() => {
    app.mount('#app');
});
