import { render } from "./SendComplete.vue?vue&type=template&id=68940770&scoped=true"
import script from "./SendComplete.vue?vue&type=script&lang=ts"
export * from "./SendComplete.vue?vue&type=script&lang=ts"
script.render = render
script.__scopeId = "data-v-68940770"
/* hot reload */
if (module.hot) {
  script.__hmrId = "68940770"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('68940770', script)) {
    api.reload('68940770', script)
  }
  
  module.hot.accept("./SendComplete.vue?vue&type=template&id=68940770&scoped=true", () => {
    api.rerender('68940770', render)
  })

}

script.__file = "src/components/send/SendComplete.vue"

export default script