import { render } from "./FileCard.vue?vue&type=template&id=48849e48&scoped=true"
import script from "./FileCard.vue?vue&type=script&lang=ts"
export * from "./FileCard.vue?vue&type=script&lang=ts"

import "./FileCard.vue?vue&type=style&index=0&id=48849e48&lang=css&scoped=true"
script.render = render
script.__scopeId = "data-v-48849e48"
/* hot reload */
if (module.hot) {
  script.__hmrId = "48849e48"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('48849e48', script)) {
    api.reload('48849e48', script)
  }
  
  module.hot.accept("./FileCard.vue?vue&type=template&id=48849e48&scoped=true", () => {
    api.rerender('48849e48', render)
  })

}

script.__file = "src/components/FileCard.vue"

export default script