import { render } from "./SendDefault.vue?vue&type=template&id=71612882&scoped=true"
import script from "./SendDefault.vue?vue&type=script&lang=ts"
export * from "./SendDefault.vue?vue&type=script&lang=ts"

import "./SendDefault.vue?vue&type=style&index=0&id=71612882&lang=css&scoped=true"
script.render = render
script.__scopeId = "data-v-71612882"
/* hot reload */
if (module.hot) {
  script.__hmrId = "71612882"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('71612882', script)) {
    api.reload('71612882', script)
  }
  
  module.hot.accept("./SendDefault.vue?vue&type=template&id=71612882&scoped=true", () => {
    api.rerender('71612882', render)
  })

}

script.__file = "src/components/send/SendDefault.vue"

export default script