
import {defineComponent} from 'vue';

import {
    IonIcon,
    IonLabel,
} from '@ionic/vue';
import {copy} from 'ionicons/icons';
import WaitButton from './WaitButton.vue';

export default defineComponent({
    name: 'CopyButton',
    props: ['link', 'disabled'],
    data() {
        return {
            // disabled: !navigator.clipboard,
            waiting: false,
        }
    },
    methods: {
        copyLink() {
            navigator.clipboard.writeText(this.link);
        },
    },
    components: {
        WaitButton,
        IonIcon,
        IonLabel,
    },
    setup() {
        return {
            copy,
        }
    },
})
