import { render } from "./Send.vue?vue&type=template&id=2c3b027c&scoped=true"
import script from "./Send.vue?vue&type=script&lang=ts"
export * from "./Send.vue?vue&type=script&lang=ts"

import "./Send.vue?vue&type=style&index=0&id=2c3b027c&scoped=true&lang=css"
import "./Send.vue?vue&type=style&index=1&id=2c3b027c&lang=css"
script.render = render
script.__scopeId = "data-v-2c3b027c"
/* hot reload */
if (module.hot) {
  script.__hmrId = "2c3b027c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2c3b027c', script)) {
    api.reload('2c3b027c', script)
  }
  
  module.hot.accept("./Send.vue?vue&type=template&id=2c3b027c&scoped=true", () => {
    api.rerender('2c3b027c', render)
  })

}

script.__file = "src/views/Send.vue"

export default script