

import {Component, defineComponent, Transition} from "vue";
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonLabel,
    IonPage,
    IonRow,
    IonText,
    IonFooter
} from "@ionic/vue";
import {sendOutline, sendSharp} from 'ionicons/icons';
import Send from "@/views/Send.vue";
import Receive from "@/views/Receive.vue";
import Footer from "@/components/Footer.vue";
import {RouterView} from "vue-router";
import {SendStep} from "@/types";

export default defineComponent({
    computed: {
        navComponent(): Component {
            return this.$route.path === '/s' ? Send : Receive;
        },
        navIcon(): string {
            return this.$route.path === '/s' ? '/assets/icon/download.svg' : sendSharp;
        },
        navButtonText(): string {
            return this.$route.path === '/s' ? 'Receive' : 'Send';
        },
        navHref(): string {
            return this.$route.path === '/s' ? '/r' : '/s';
        },
        rotated(): boolean {
            return this.$route.path !== '/s';
        },
    },
    methods: {
        toggleNav() {
            // TODO: move into store?
            this.$route.path === '/s' ?
                    this.$router.replace('/r') :
                    this.$router.replace('/s');
        },
        goToSendReload() {
            this.$router.replace('/s');
            setTimeout(() => {
                window.location.reload()
            }, 100)
        }
    },
    components: {
        Transition,
        IonPage,
        IonContent,
        IonFooter,
        IonGrid,
        IonRow,
        IonCol,
        IonText,
        IonLabel,
        IonButton,
        IonIcon,
        // IonRouterOutlet,
        // IonNavLink,
        RouterView,
        Footer,
    },
    setup() {
        return {
            SendStep,
        }
    }
})
