

import {IonCard, IonIcon, IonSpinner, IonText} from "@ionic/vue";
import {document} from 'ionicons/icons';
import {basename, extension, sizeWithUnit} from "@/util";
import {defineComponent} from "vue";

export default defineComponent({
    name: "FileCard",
    props: ['name', 'size'],
    components: {
        IonCard,
        IonIcon,
        IonText,
        IonSpinner,
    },
    setup() {
        return {
            document,
            basename,
            extension,
            sizeWithUnit,
        }
    },
})
