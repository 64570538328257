
import {defineComponent} from "vue";
import {mapGetters, mapState} from "vuex";
import {
    IonButton,
    IonCol,
    IonGrid,
    IonIcon,
    IonLabel,
    IonProgressBar,
    IonRow,
    IonText
} from "@ionic/vue";
import {close} from "ionicons/icons";

import Card from "@/components/Card.vue";

export default defineComponent({
    name: "Progress",
    props: ['active', 'cancel', 'progressMsg'],
    computed: {
        ...mapState(['progress', 'progressHung']),
        ...mapGetters(['progressETA']),
        _progressETA(): string {
            return (this.progressETA as unknown as string) !== '' ?
                    this.progressETA :
                    this.progressMsg;
        },
    },
    components: {
        IonGrid,
        IonRow,
        IonCol,
        IonText,
        IonButton,
        IonLabel,
        IonIcon,
        IonProgressBar,
        Card,
    },
    setup() {
        return {
            close,
        };
    }
})
