
import {
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonButton,
    IonIcon,
    IonProgressBar,
    IonLabel,
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {mapState, mapActions, mapMutations} from 'vuex';
import {enterOutline, exitOutline, exit, cloudDownloadOutline, close} from 'ionicons/icons';

import router from '@/router/index.ts'
import {
    ACCEPT_FILE,
    ALERT_MATCHED_ERROR,
    RESET_CODE,
    RESET_PROGRESS,
    SET_PROGRESS
} from "@/store/actions";
import {TransferProgress} from "@/go/wormhole/types";
import Card from "@/components/Card.vue";
import FileCard from "@/components/FileCard.vue";

declare interface ReceiveConsentData {
    receivingPromise?: Promise<TransferProgress>;
}

export default defineComponent({
    name: "ReceiveConsent",
    props: ['active', 'back', 'next', 'complete', 'reset'],
    data(): ReceiveConsentData {
        return {
            receivingPromise: undefined,
        }
    },
    computed: {
        ...mapState(['config', 'code', 'fileMeta', 'progress']),
    },
    methods: {
        ...mapActions([ACCEPT_FILE, ALERT_MATCHED_ERROR]),
        ...mapMutations([SET_PROGRESS, RESET_CODE, RESET_PROGRESS]),
        async download() {
            this.next();
            try {
                await this[ACCEPT_FILE]();
            } catch {
                this[RESET_CODE]();
                this.reset();
            }
            await this.fileMeta.done
            this.complete();
        },
        cancel() {
            // TODO: move into action.
            // TODO: *use reject here.
            this.back();
            this[RESET_PROGRESS]();
        },
    },
    components: {
        IonGrid,
        IonRow,
        IonCol,
        IonText,
        IonButton,
        IonIcon,
        IonLabel,
        IonProgressBar,
        Card,
        FileCard,
    },
    setup() {
        return {
            cloudDownloadOutline,
            close,
            enterOutline,
            exit,
            exitOutline,
            router,
        }
    }
});
