
import {
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonButton,
    IonIcon,
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {mapState} from "vuex";
import {downloadOutline, sendSharp} from 'ionicons/icons'

import Card from "@/components/Card.vue";
import FileCard from "@/components/FileCard.vue";

export default defineComponent({
    name: "ReceiveComplete",
    props: ['active', 'next'],
    computed: {
        ...mapState(['fileMeta']),
    },
    components: {
        IonGrid,
        IonRow,
        IonCol,
        IonText,
        IonIcon,
        IonButton,
        Card,
        FileCard,
    },
    setup() {
        return {
            downloadOutline,
            sendSharp,
        }
    },
});
