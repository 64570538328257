
import {defineComponent} from "vue";
import {
    IonButton,
    IonIcon,
    IonText
} from "@ionic/vue";

import Card from "@/components/Card.vue";
import DropZone from "@/components/send/DropZone.vue";

export default defineComponent({
    name: "SendDefault",
    props: ['select', 'active'],
    components: {
        IonButton,
        IonIcon,
        IonText,
        Card,
        DropZone,
    },
    methods: {},
});
