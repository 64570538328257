
import {defineComponent} from "vue";
import {mapGetters, mapMutations, mapState} from "vuex";

import {RESET_PROGRESS} from "@/store/actions";
import Progress from "@/components/Progress.vue";
import FileCard from "@/components/FileCard.vue";

export default defineComponent({
    name: "SendProgress.vue",
    props: ['active', 'back'],
    computed: {
        ...mapState(['fileMeta']),
        ...mapGetters(['progressETA']),
        _progressETA() {
            return (this.progressETA as unknown as string) !== '' ?
                    this.progressETA :
                    'Waiting for receiver to complete transfer...';
        },
    },
    methods: {
        ...mapMutations([RESET_PROGRESS]),
        // TODO: lift up.
        cancel() {
            this.back();
            this[RESET_PROGRESS]();
        },
    },
    components: {
        Progress,
        FileCard,
    }
})
