export enum SendStep {
    Default = 0,
    Instructions,
    Progress,
    Complete,
}
export enum ReceiveStep {
    Default = 0,
    Consent,
    Progress,
    Complete,
}
