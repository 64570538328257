import { render } from "./Receive.vue?vue&type=template&id=109fa019&scoped=true"
import script from "./Receive.vue?vue&type=script&lang=ts"
export * from "./Receive.vue?vue&type=script&lang=ts"
script.render = render
script.__scopeId = "data-v-109fa019"
/* hot reload */
if (module.hot) {
  script.__hmrId = "109fa019"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('109fa019', script)) {
    api.reload('109fa019', script)
  }
  
  module.hot.accept("./Receive.vue?vue&type=template&id=109fa019&scoped=true", () => {
    api.rerender('109fa019', render)
  })

}

script.__file = "src/views/Receive.vue"

export default script