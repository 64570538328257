
import {
    IonCol,
    IonGrid,
    IonInput,
    IonRow,
    IonSpinner,
    IonText,
} from "@ionic/vue";
import {mapActions, mapMutations, mapState} from "vuex";
import {ALERT_MATCHED_ERROR, COMPLETE_CODE_WORD, SAVE_FILE, SET_CODE} from "@/store/actions";
import {defineComponent} from "vue";

import AutoComplete from "@/components/AutoComplete.vue"
import WaitButton, {DefaultDuration} from "@/components/WaitButton.vue";
import {ErrInvalidCode} from "@/errors";
import {CODE_REGEX} from "@/util";
import Card from "@/components/Card.vue";

const errorColor = 'warning-red';
const exampleColor = 'dark-grey';
const errorText = ErrInvalidCode.message;
const exampleText = 'E.g.: 7-guitarist-revenge';

export default defineComponent({
    name: "ReceiveDefault",
    props: ['active', 'next', 'reset'],
    data() {
        return {
            exampleErrorColor: exampleColor,
            exampleErrorText: exampleText,
            waiting: false,
        };
    },
    computed: {
        ...mapState(['code']),
        // TODO: vuex getter?
        codeIsValid(): boolean {
            return CODE_REGEX.test(this.code as unknown as string);
        },
        blurInvalid(): boolean {
            return this.code !== '' && this.exampleErrorColor === errorColor;
        },
        _code: {
            get: function (): string {
                return this.code;
            },
            set: function (code: string) {
                this[SET_CODE](code);
            }
        }
    },
    methods: {
        ...mapActions([SAVE_FILE, ALERT_MATCHED_ERROR]),
        ...mapMutations([SET_CODE, COMPLETE_CODE_WORD]),
        async _next(): Promise<void> {
            // TODO: remove
            window.setTimeout(() => {
                this.waiting = false;
            }, DefaultDuration);
            this.waiting = true;

            try {
                await this[SAVE_FILE](this.code);
                this.next();
            } catch (error) {
                console.error(error);
                this.reset();
            }
        },
        async onInput(event: Event): Promise<void> {
            if (this._code === '') {
                return;
            }
        },
        validate(): void {
            if (this._code === '' || CODE_REGEX.test(this._code)) {
                this.exampleErrorText = exampleText;
                this.exampleErrorColor = exampleColor;
            } else {
                this.exampleErrorText = errorText;
                this.exampleErrorColor = errorColor;
            }
        },
        completeCodeWord(event: KeyboardEvent): void {
            event.preventDefault();
            this[COMPLETE_CODE_WORD]();
        },
    },
    components: {
        IonGrid,
        IonRow,
        IonCol,
        IonText,
        IonInput,
        IonSpinner,
        WaitButton,
        AutoComplete,
        Card,
    }
})
