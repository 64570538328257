import { render } from "./DropZone.vue?vue&type=template&id=2ad8cdbc&scoped=true"
import script from "./DropZone.vue?vue&type=script&lang=ts"
export * from "./DropZone.vue?vue&type=script&lang=ts"

import "./DropZone.vue?vue&type=style&index=0&id=2ad8cdbc&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-2ad8cdbc"
/* hot reload */
if (module.hot) {
  script.__hmrId = "2ad8cdbc"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2ad8cdbc', script)) {
    api.reload('2ad8cdbc', script)
  }
  
  module.hot.accept("./DropZone.vue?vue&type=template&id=2ad8cdbc&scoped=true", () => {
    api.rerender('2ad8cdbc', render)
  })

}

script.__file = "src/components/send/DropZone.vue"

export default script