
import {
    IonCard,
    IonCardContent,
    IonText,
} from '@ionic/vue';
import {defineComponent} from "vue";
import {mapState} from "vuex";

export default defineComponent({
    name: "AutoComplete",
    computed: {
        ...mapState(['code', 'suggestedWord']),
        opacity(): number {
            return this.suggestedWord ? 1 : 0;
        }
    },
    components: {
        IonCard,
        IonCardContent,
        IonText,
    }
});
