import { render } from "./SendInstructions.vue?vue&type=template&id=28380734&scoped=true"
import script from "./SendInstructions.vue?vue&type=script&lang=ts"
export * from "./SendInstructions.vue?vue&type=script&lang=ts"

import "./SendInstructions.vue?vue&type=style&index=0&id=28380734&lang=css&scoped=true"
script.render = render
script.__scopeId = "data-v-28380734"
/* hot reload */
if (module.hot) {
  script.__hmrId = "28380734"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('28380734', script)) {
    api.reload('28380734', script)
  }
  
  module.hot.accept("./SendInstructions.vue?vue&type=template&id=28380734&scoped=true", () => {
    api.rerender('28380734', render)
  })

}

script.__file = "src/components/send/SendInstructions.vue"

export default script