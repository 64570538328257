import { render } from "./SendProgress.vue?vue&type=template&id=0eebf33c&scoped=true"
import script from "./SendProgress.vue?vue&type=script&lang=ts"
export * from "./SendProgress.vue?vue&type=script&lang=ts"
script.render = render
script.__scopeId = "data-v-0eebf33c"
/* hot reload */
if (module.hot) {
  script.__hmrId = "0eebf33c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0eebf33c', script)) {
    api.reload('0eebf33c', script)
  }
  
  module.hot.accept("./SendProgress.vue?vue&type=template&id=0eebf33c&scoped=true", () => {
    api.rerender('0eebf33c', render)
  })

}

script.__file = "src/components/send/SendProgress.vue"

export default script