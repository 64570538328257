
import {
    IonButton,
    IonCol,
    IonGrid,
    IonIcon,
    IonInput,
    IonRow,
    IonText,
    IonLabel,
} from '@ionic/vue';
import {copy, close} from 'ionicons/icons';
import {defineComponent} from 'vue';
import {mapState, mapActions, mapMutations} from 'vuex';

import {NEW_CLIENT, RESET_PROGRESS} from "@/store/actions";
import Card from "@/components/Card.vue";
import CopyButton from "@/components/CopyButton.vue";
import FileCard from "@/components/FileCard.vue";

export default defineComponent({
    name: "SendInstructions",
    props: ['active', 'selectFile', 'file', 'back'],
    computed: {
        ...mapState(['host', 'code', 'progress']),
        // TODO: vuex getter?
        link(): string {
            // TODO: move to utils.
            return `${this.host}/#/${this.code}`;
        },
        // TODO: vuex getter?
        shortLink(): string {
            return this.link.replace(/https?:\/\//, '');
        },
        // TODO: vuex getter?
        linkReady(): boolean {
            // TODO: consolidate with CODE_REGEX.
            return /\/#\/\d+-(\w-?)+$/.test(this.link);
        }
    },
    methods: {
        ...mapActions([NEW_CLIENT]),
        ...mapMutations([RESET_PROGRESS]),
        cancel() {
            // TODO: move up to Send.vue
            this.back();
            this[RESET_PROGRESS]();

            // this.reset();
        },
        reset() {
            // TODO: remove.
            // this[NEW_CLIENT]();
        },
    },
    components: {
        IonGrid,
        IonRow,
        IonCol,
        IonText,
        IonLabel,
        IonButton,
        IonIcon,
        IonInput,
        Card,
        CopyButton,
        FileCard,
    },
    setup() {
        return {
            copy,
            close,
        }
    },
});
