import { render } from "./ReceiveDefault.vue?vue&type=template&id=7fc653ba&scoped=true"
import script from "./ReceiveDefault.vue?vue&type=script&lang=ts"
export * from "./ReceiveDefault.vue?vue&type=script&lang=ts"

import "./ReceiveDefault.vue?vue&type=style&index=0&id=7fc653ba&lang=css&scoped=true"
script.render = render
script.__scopeId = "data-v-7fc653ba"
/* hot reload */
if (module.hot) {
  script.__hmrId = "7fc653ba"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7fc653ba', script)) {
    api.reload('7fc653ba', script)
  }
  
  module.hot.accept("./ReceiveDefault.vue?vue&type=template&id=7fc653ba&scoped=true", () => {
    api.rerender('7fc653ba', render)
  })

}

script.__file = "src/components/receive/ReceiveDefault.vue"

export default script