
import {
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonLabel,
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex';
import {enterOutline, exitOutline, exit, cloudDownloadOutline, close} from 'ionicons/icons';

import router from '@/router/index.ts'
import {sizeToClosestUnit} from "@/util";
import {NEW_CLIENT, RESET_PROGRESS} from "@/store/actions";
import {FileMeta} from "@/store";
import FileCard from "@/components/FileCard.vue";
import Progress from "@/components/Progress.vue";

declare interface ReceiveProgressData {
    done?: Promise<void>;
}

export default defineComponent({
    name: "ReceiveProgress",
    props: ['active', 'back', 'next'],
    data(): ReceiveProgressData {
        return {
            done: undefined,
        }
    },
    computed: {
        ...mapState(['config', 'code', 'fileMeta', 'progress', 'progressHung']),
        ...mapGetters(['progressETA']),
        fileSize(): string {
            // TODO: cleanup.
            const fileMeta = this.fileMeta as unknown as FileMeta;
            return sizeToClosestUnit(fileMeta.size);
        },
        // TODO: calculate!
    },
    methods: {
        ...mapActions([NEW_CLIENT]),
        ...mapMutations([RESET_PROGRESS]),
        cancel() {
            // TODO: move into action.
            // TODO: *use reject here.
            this.back();
            this[RESET_PROGRESS]();
            // this[NEW_CLIENT]();
        },
    },
    components: {
        IonRow,
        IonCol,
        IonButton,
        IonIcon,
        IonLabel,
        FileCard,
        Progress,
    },
    setup() {
        return {
            cloudDownloadOutline,
            close,
            enterOutline,
            exit,
            exitOutline,
            router,
        }
    }
});
